#view-reseller-info-form-container {
    margin: 50px auto;
    width: 100%;
    max-width: 1200px;
    .info-form-section-title {
        border-bottom: 1px solid lightgrey;
    }

    .form-block {
        margin: auto;
    }

    .form-paper {
        padding: 30px;
    }

    .form-field {
        margin: 8px 0;
        display: flex;
    }

    .form-data-label {
        flex-shrink: 1;
        font-weight: bold;
    }

    .form-data-value {
        flex-grow: 1;
        border-bottom: 1px solid #009cff99;
        margin-left: 8px;
    }

    .address-block, .contact-block {
        margin-top: 8px;
        margin-bottom: 24px;
    }
}