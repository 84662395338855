$black: #081016;
$lblack: #111f2b;
$red: #ff4d4d;
$lgray: #5b5f69;
$gray: #d0d5d9;
$subtle: #f2f2f2;
$blue: #282bf6;
$neutral: #697790;
$offwhite: #f2f5f8;
$white: #fdfdfe;
$text: #1b2733;
$insane: #7effcf;
$darkModeWhite: #21252b;
$darkModeGray: #282c34;
$darkModeLightText: #6e7179;
$darkModeText: #d7dae0;

$pd: #9288c0;

$highlight-green: #c6e0c6;
$green: #2b902b;

$subtle-shadow: rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;

@mixin gradient {
  background: #081016;
  background: -moz-linear-gradient(-45deg, #081016 0%, #2b3991 99%);
  background: -webkit-linear-gradient(-45deg, #081016 0%, #2b3991 99%);
  background: linear-gradient(135deg, #081016 0%, #2b3991 99%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#081016', endColorstr='#2b3991',GradientType=1 );
}
