@import "../../../partials/variables.scss";

.address-form {
    border-bottom: 1px solid grey;
    padding-bottom: 30px;
    min-width: 800;
    p {
        text-align: left;
        padding-bottom: 0;
    }
    .contact-card {
        background-color: whitesmoke;
    }
}

.contact-form {
    border-bottom: 1px solid grey;
    padding-bottom: 30px;
    min-width: 800;
    p {
        text-align: left;
        padding-bottom: 0;
    }
    .contact-card {
        background-color: whitesmoke;
    }
}


.reseller-info-form {
    width: 100%;
    display: flex;
  }
  .disabled {
    pointer-events: none;
  }


.reseller-info-form-wrapper {
    width: 100%;
    background-color: #f2f5f8;
    max-width: 1200px;
    margin: 30px auto;
    padding-bottom: 30px;
    .form-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 50px;
      box-shadow: $subtle-shadow;
      background: white;
    }
    h2 {
      font-size: 18px;
      width: 100%;
      text-align: left;
      padding: 20px 0;
      text-transform: uppercase;
      letter-spacing: 3px;
    }
    h3 {
      font-size: 16px;
      text-align: left;
    }
  }