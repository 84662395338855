@import "../../partials/variables.scss";

.po-upload-container {
    background: white;
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 30px 0px;
    border-radius: 10px;
    padding: 20px;
    margin-top: 40px;
    .upload-container,
    .upload-wrapper {
        box-shadow: none;
    }
    .MuiDropzonePreviewList-root {
        display: block;
    }
}


.order-contents {
    .row-heads {
        .bstock {
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        
        .ch {
            padding: 12px;
        }
    }
    .line-item {
      padding: 12px;
      input[type="number"] {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid $gray;
        max-width: 70px;
      }
    }
    .delete {
      padding-left: 20px;
      width: 50px;
      cursor: pointer;
      &:hover {
        color: $red;
      }
    }
    .price {
      flex: 1;
      text-align: center;
    }
    .bstock {
      flex: 1;
      text-align: center;
      max-width: 70px;
    }
    .price-per {
      flex: 1;
      text-align: center;
    }
    .qty {
      flex: 1;
      text-align: center;
    }
    .num {
      flex: 5;
    }
    .order-item-notes {
        padding: 8px;
        min-height: 0;
    }
  }