@import "../partials/variables.scss";

.baseball-icon {
    max-width: 50px;
    margin: auto; 
    margin-bottom: -26px;
    display: inline-block;
}

#product-development-details-editor .rdw-editor-main {
    min-height: 400px;
}