.mobile-hidden {
  display: none;
}

.settings-bar {
  padding: 20px 0;
}

//Auth

.inventory {
  .num {
    flex: 8;
  }
  .price {
    flex: 2;
  }

  .qty {
    flex: 1;
  }
  .row-heads {
    &:after {
      flex: 2;
    }
  }
}

.inventory-results {
  .inventory-search {
    .prod-search {
      flex: 4;
      margin-right: 20px;
    }
    .loc-select {
      flex: 1;
    }
  }
}

.content-blocks {
  .col1 {
    width: 35%;
  }
  .col2 {
    width: 60%;
  }
}

.reseller-profile {
  margin-right: 10px;
  margin-bottom: 0px;
}

.top-banner {
  display: flex;
}

.order-history {
  .shipto,
  .carrier,
  .total {
    display: inherit;
  }
}

.user-search {
  .email,
  .company,
  .account-level,
  .loggedin {
    display: inherit;
  }
}

.reseller-profile {
  flex: 3;
}
.members.reseller-group {
  flex: 9;
}

.two-col {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.prod-media-browser {
  .gallery {
    .container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.invite-header {
  padding-bottom: 0px;
  p {
    font-size: 1.2em;
  }
}

.invite-container {
  padding: 20px 50px 50px 50px;
  border-radius: 10px;
  .invite-email {
    font-size: 1em;
  }
}

.fixture-profiles {
  .container {
    .grid-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.tools {
  .tools-container {
    grid-template-columns: repeat(3, 1fr);
    a {
      border: none;
    }
  }
}

.warranty-center {
  padding: 2em 0;
  .form-container {
    width: 80%;
  }
}

.progress-bar {
  width: 18%;
  flex-wrap: nowrap;
  .progress-icon {
    width: 100%;
  }
  .progress-icons {
    width: 100px;
    flex-direction: column;
    padding-right: 2em;
  }
}

.progress-tube {
  height: 800px;
  width: 15px;
}
