.active-card {
    border: 5px solid rgba(0, 135, 197, 0.6);
}


.payment-book {
    display: flex;
    flex-wrap: wrap;
    h2 {
      font-size: 2em;
    }
    p {
      font-size: 1.2em;
    }
    .highlight {
      text-transform: none;
      margin-top: 10px;
      padding: 10px;
      width: 100%;
      text-align: left;
      font-size: 14px;
      margin-bottom: 20px;
      i {
        padding-right: 10px;
      }
    }
  }