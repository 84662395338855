//Auth

.hide-mobile {
  display: inherit;
}

.add-to-po {
  p {
    padding-left: 5px;
  }
}

.prod-media-browser {
  .gallery {
    .container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.fixture-profiles {
  .container {
    .grid-container {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.iris-quote-builder {
  .home {
    .calc {
      max-width: 346px;
      padding: 30px;
      overflow-y: scroll;
    }
    .view {
      width: 100%;
    }
  }

  .mobile-alert {
    display: none;
  }
}



.pd-sidebar {width: 30%;max-width: 320px; display: block; height: 100vh;
  background: #02060b;}

.pd-board {flex: 1}

.pd-boards {grid-template-columns: repeat(2, 1fr);}

.pd-sales-numbers {min-height: 30vh;}

.pd-dbotm {height: 25vh;}

.pd-timer {height: 30vh;}
