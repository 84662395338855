.contact-card {
  padding: 25px;
  margin: 15px 0;

  i {
    font-size: 24px;
    color: blue;
    margin: 5px;
  }

  .center-text {
    text-align: center;
  }

  .edit-contact {
    cursor: pointer;
    color: #697790;
  }

  .contact-type-flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .save-error {
    text-align: center;
    color: tomato;
    font-size: 1.3em;
  }

  .tomato {
    color: tomato;
  }

  .green {
    color: green;
  }

  .orange {
    color: orange;
  }

  .blue {
    color: blue;
  }

}

.body-message {
  text-align: center;
  margin: 25px auto;
  font-size: 2em;
  font-weight: bold;
}

.contact-modal {
  padding: 15px;
  max-width: 1000px;
}

