@import url("https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  padding: 0;
  background: $offwhite !important;
  scroll-behavior: smooth;
}

html,
body,
#root {
  height: 100%;
  scroll-behavior: smooth;
}

h1 {
  font-weight: normal;
  line-height: 1.2;
  font-size: 2.5em;
}

h3.block-title {
  color: $lgray;
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 3px solid $blue;
  display: table;
}

a {
  color: $black;
  text-decoration: none;
  border-bottom: 1px solid $blue;
}

main {
  overflow-y: hidden;
}

.view {
  padding: 100px 0 100px 0;
  position: relative;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.view-fullwidth {
  padding: 100px 0;
  position: relative;
  background: #11101b;
  margin: 0 auto;
}

input#outlined-basic {
  color: black;
}

p.error {
  color: white;
  background: $red;
  border-radius: 25px;
  padding: 10px;
  text-align: center;
}

//ui
.close {
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  color: $neutral;
  z-index: 99999;
}
.btn-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding-bottom: 10px;
}

.btn {
  padding: 10px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  font-family: "Poppins" !important;
}

.btn.otl {
  background: transparent;
  border: 1px solid $neutral;
  color: $lgray;
  box-shadow: none !important;
  &:hover {
    color: white;
  }
}

.btn.green,
.btn.red {
  color: white;
}
.btn.green {
  background: $green;
  &:hover {
    background: $green;
    filter: brightness(90%);
  }
}

.btn.blue {
  background: $blue;
}

.btn.red {
  background: $red;
}

.btn-container {
  display: flex;
  justify-content: space-between;
}
.sidenav-button.active {
  background: $blue;
  color: white;
  &:hover {
    background: $blue;
  }
}
.sidenav-button {
  text-align: center;
  margin: 0 auto;
  padding: 0;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background-color: $lblack;
    transition: 0.3s;
    p,
    i {
      color: white;
    }
  }
  img {
    margin: 5px 0;
  }
  i {
    margin: 5px 0;
    font-size: 18px;
    padding: 10px;
  }
  p {
    padding-bottom: 5px;
    font-size: 11px;
    margin: 0;
  }
}

.input-full {
  width: 100%;
}

.fine {
  font-size: 0.75em;
}

.underline {
  display: table;
  border-bottom: 1px solid gray;
  cursor: pointer;
}

.underline-blue {
  border-bottom: 1px solid $blue;
}
//Auth Portal

.badge {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;

  h1 {
    font-size: 21px;
    font-weight: 500;
    padding-left: 17px;
    color: #424242;
  }
  img {
    display: block;
  }
}

@keyframes slideup {
  from {
    transform: translateY(30%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.auth-portal {
  display: flex;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  .auth {
    box-sizing: border-box;
    background-color: var(--page-background-color);
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.25);
    max-width: 500px;
    width: 100%;
    min-height: 700px;
    overflow-y: auto;
    position: relative;
    padding: 40px 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: flex;
    height: 100%;
  }
  .splash {
    -webkit-transition: background-color var(--fast) var(--transition);
    transition: background-color var(--fast) var(--transition);
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background-size: cover;
    background-position: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.overview {
  .wrapper {
    max-width: 800px;
    margin: 0 auto;
  }
  .highlight {
    display: table;
    text-transform: inherit;
  }
  .tools-container {
    width: 90%;
    display: block !important;
    .top-info {
      font-size: 1.4em;
    }
    display: block;
  }
}

//Top Nav

.alert {
  background: $red;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 20px 0 20px 0;
  display: flex;
  align-items: center;
  padding: 0 25px;
  i {
    margin-right: 10px;
  }
  span {
    margin-right: 10px;
  }
  a {
    color: $black;
    font-weight: bold;
    text-decoration: none;
    display: table;
    border-bottom: 1px solid $black;
  }
}

.account-popover {
  margin-top: 20px;
  .account-container {
    padding: 30px;
  }
  .email {
    color: $lgray;
    font-size: 12px;
  }
  .account-level {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  p {
    margin: 0;
    padding-bottom: 15px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      opacity: 0.6;
    }
  }
}

.user-as {
  margin-right: 10px;
  background: #538c53;
  padding: 2px 20px;
  font-size: 12px;
  border-radius: 5px;
  color: white;
  display: table;
  min-width: 220px;
  i {
    font-weight: bold;
    margin-left: 7px;
  }
}

.top-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .tool-bar {
    position: absolute;
    right: 20px;
    top: 0px;
    display: flex;
    align-items: center;

    i {
      cursor: pointer;
      opacity: 0.6;
      margin-right: 20px;
      transition: 0.3s;
      &:hover {
        opacity: 1;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  #size-small-standard {
    padding: 0 10px;
    font-size: 12px;
    color: white;
  }

  .customer-chooser {
    width: 260px;
    border: 1px solid #4d4d4d;
    margin-right: 20px;
    border-radius: 5px;
    fieldset {
      border: none;
    }
    svg {
      fill: $blue;
    }
    label {
      display: none;
      color: white;
      font-size: 12px;
      opacity: 0.7;
      position: relative;
    }
  }
}

//create po

.results-container {
  background: $black;
  margin-top: -10px;
  max-width: 400px;
  border-bottom-left-radius: 10px;
  height: 100px;
  overflow-y: scroll;
  li {
    cursor: pointer;
    color: $lgray;
    list-style-type: none;
    padding: 5px 5px 5px 20px;
    &:hover {
      color: white;
      background: $lblack;
    }
  }
}

.line-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  box-shadow: $subtle-shadow;
  padding: 0 0 0 20px;
  border-radius: 5px;
  background: white;
  flex: 1;
}

.line-item .MuiAccordionSummary-content {
  align-items: center;
}

.current-po {
  .item {
    display: flex;
    background: $subtle;
    padding: 10px;
  }
  .delete-item {
    padding: 10px 15px;
  }
}

.row-heads {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  font-weight: bold;
  color: $lgray;
  margin-top: 20px;
  font-size: 12px;
}

.row-heads.co {
  &:after {
    content: "";
    display: block;
    padding: 10px 22px;
  }
}

.order-info {
  display: flex;
  padding: 5px 0;
  border-bottom: 1px solid $gray;
  .delete {
    i {
      color: $red;
      cursor: pointer;
    }
  }
}
.incrementer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .less,
  .more {
    flex: 2;
    text-align: center;
    font-size: 20px;
    color: $lgray;
    cursor: pointer;
    &:hover {
      color: $blue;
    }
  }

  .qty-button {
    flex: 6;
  }
}

.currently-select {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .prod-num,
  .prod-stock,
  .prod-price,
  .prod-qty {
    background: $subtle;
    padding: 10px;
    margin: 2px;
  }
  .prod-num {
    width: 100%;
  }

  .prod-price {
    width: 48%;
  }

  .prod-stock {
    width: 48%;
  }

  .prod-qty {
    width: 100%;
    text-align: center;
  }
}

.volume-discount {
  i {
    margin-left: 5px;
    color: $blue;
    font-size: 18px;
  }
  h4 {
    margin: 0;
  }
}

.tt-flex {
  .item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $gray;
    padding: 5px 0;
  }
}

.inventory {
  .customer {
    cursor: zoom-in;
  }
  .media {
    flex: 3;
    text-align: center;
    i {
      color: $gray;
      font-size: 1.2em;
    }
  }
  i.active {
    color: $blue;
    cursor: pointer;
  }
  .num {
    flex: 3;
    word-break: break-word;
    display: flex;
    align-items: center;
    .highlight {
      margin-left: 5px;
    }
  }
  .price {
    display: flex;
    flex: 2;
    align-items: center;
    font-weight: bold;
    i {
      color: $green;
      font-size: 20px;
      margin-left: 5px;
    }
  }

  .qty {
    flex: 1;
  }
  .row-heads {
    padding-bottom: 20px;
  }
}

.inventory-results {
  .row-heads {
    .add-to-po {
      border: none;
      color: inherit;
      pointer-events: none;
    }
  }
  .line-item {
    padding: 12px 15px;
    .num {
      padding: 0;
      i {
        padding-right: 1em;
        color: $gray;
      }
      i.active {
        color: $blue;
      }
    }
  }
  .sub-total {
    font-weight: bold;
    float: right;
    padding-top: 10px;
  }
  .inventory-search {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    .prod-search {
      width: 100%;
    }
    .loc-select {
      width: 100%;
    }
  }
}

.add-to-po {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 20px;
  background: transparent;
  border: 1px solid $gray !important;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  span {
    font-weight: bold;
    color: $blue;
    font-size: 16px;
  }
  i {
    color: $blue;
    padding: 12px;
  }
  p {
    color: rgba(255, 255, 255, 0.5);
  }
  &:hover {
    background: $blue !important;
    filter: brightness(110%);
    transition: 0.2s ease-in-out;
    i {
      color: white;
    }
  }
}

.po-cart.active {
  background: $blue;
}
.po-cart {
  position: relative;
  margin-right: 20px;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
  .qty {
    top: 4px;
    left: -9px;
    background: $red;
    color: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-align: center;
    position: absolute;
  }
  i {
    padding: 15px;
    font-size: 18px;
    color: white;
  }
}

.checkout-form {
  .back-to-edit {
    display: flex;
    align-items: center;
    i {
      padding-right: 10px;
    }
  }
}

.checkout {
  h1 {
    padding-bottom: 10px;
  }
}
.stepper {
  display: flex;
  justify-content: space-around;
  max-width: 790px;
  margin: 0 auto;
}

.numeral-bubble {
  border: 1px solid $lgray;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.notch {
  text-align: center;
  padding: 20px 0 50px 0;
  cursor: pointer;
  flex-wrap: wrap;
  transition: 0.3s;
  display: flex;
  align-items: center;
  &:after {
    content: " ";
    display: block;
    border-bottom: 2px solid $gray;
  }
  &:hover {
    transition: 0.3s;
    transform: scale(1.05);
  }
  .numeral-bubble {
    margin: 0 auto;
  }
  h4 {
    width: 100%;
    margin: 0;
    margin-top: 5px;
    background: $subtle;
    padding: 5px 15px;
    border-radius: 25px;
    color: $lgray;
    font-weight: 600;
    font-size: 12px;
  }
}

.notch.active {
  h4 {
    background: $blue !important;
    color: white;
  }
  .numeral-bubble {
    border: 1px solid $blue;
    background: $blue;
    color: white;
  }
}

.top-banner {
  margin-top: 20px;
  height: 200px;
  background: tan;
  background-size: cover;
  background-position: center;
  display: none;
  align-items: center;
  justify-content: center;
  h1 {
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    letter-spacing: 8px;
    text-shadow: 0px 0px 15px $black;
  }
}

//Settings
.settings-bar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.settings-bar {
  display: flex;
  overflow-y: scroll;
  

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  .item {
    margin-right: 30px;
    cursor: pointer;
    &:hover {
      border-bottom: 2px solid $blue;
    }
  }
  .item.active {
    border-bottom: 2px solid $blue;
  }
}

/// My Network

.my-network {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .info-header {
    border: 1px solid lightgray;
    padding: 20px;
    border-radius: 10px;
    font-size: 0.75em;
    display: table;
    margin-bottom: 30px;
    h2,
    h3 {
      margin: 0;
    }
    h3 {
      color: $lgray;
      font-weight: normal;
    }
    .blue {
      margin-top: 10px;
    }
  }
}

.avatar-default.large {
  width: 80px;
  height: 80px;
  font-size: 30px;
}

.avatar-default {
  cursor: pointer;
  margin-right: 10px;
  text-transform: uppercase;
  transition: 300ms transform;
  position: relative;
  overflow: visible !important;
  &:hover {
    transform: scale(1.1);
    transition: 300ms transform;
  }
  i {
    color: $green;
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 14px;
  }
}

.profile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.reseller-profile {
  background: $white;
  margin-bottom: 20px;
  padding: 20px 50px 50px 50px;
  width: 100%;
  box-shadow: $subtle-shadow;
  h5 {
    color: $neutral;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      padding: 2px 0;
      i {
        margin-right: 5px;
        color: $lgray;
      }
    }
  }
  .user-group {
    display: flex;
    flex-wrap: wrap;
    h5 {
      width: 100%;
    }
  }
}

.reseller-group {
  flex: 8;
  box-shadow: $subtle-shadow;
}

.user-info {
  text-align: center;
  .base-info {
    padding: 50px 100px 20px 100px;
  }
  .interaction {
    padding: 0 100px 20px 100px;
  }
  .avatar-default {
    margin: 0 auto;
  }
  h3 {
    font-size: 1.5em;
    margin: 0;
    padding-top: 10px;
  }
  h5 {
    font-size: 1em;
    margin: 0;
  }
  .btn {
    margin-top: 10px;
  }
}

.tf-static {
  border: 1px solid #d0d0d0;
  padding: 15px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.members {
  background: $white;
  padding: 20px;
  border-radius: 10px;
  .sub-member {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    h5 {
      width: 100%;
    }
    .name {
      flex: 8 !important;
    }
    .email {
      flex: 7;
    }
    .status {
      flex: 1;
    }
    .member {
      margin-bottom: 10px;
      background: $neutral;
    }
  }

  .member {
    background: white;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #d0d0d0;

    .name {
      flex: 10;
      display: flex;
      align-items: center;
      .highlight {
        margin-left: 10px;
      }
    }
    .member-count {
      flex: 2;
      text-align: left;
    }
    i {
      cursor: pointer;
      flex: 1;
      text-align: center;
      color: gray;
      transition: 0.1s;
    }
    i.active {
      transition: 0.1s;
      transform: rotate(180deg);
    }
  }
}

.green {
  color: $green;
}

.highlight.red {
  background: #fdcdcd;
  color: #ff6b6b;
}

.highlight.purple {
  background: #d5a0d5;
  color: #962096;
}

.highlight.green {
  color: $green;
  background: $highlight-green;
}

.highlight.blue {
  color: white;
  background: $blue;
}

.highlight.neutral {
  color: $neutral;
  background: transparent;
}

.highlight.subtle {
  color: #5b6773;
  background: #d0d9e2;
}

.highlight {
  font-weight: bold;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  text-transform: capitalize;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}
.tools {
  .tools-container {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(1, 1fr);
    i {
      position: absolute;
      top: 20px;
      right: 20px;
      color: rgba(255, 255, 255, 0.6);
      font-size: 18px;
    }
    .top-info {
      position: absolute;
      top: 30px;
    }
    h2,
    h5 {
      margin: 5px 0;
    }
    h2,
    h5,
    .bottom-info {
      text-shadow: 1px 1px 10px black;
    }
    h5 {
      opacity: 0.7;
    }

    .card {
      color: white;
      flex-basis: 300px;
      min-height: 400px;
      padding: 30px;
      overflow: hidden;
    }
  }
}
.card.active {
  border: 2px solid rgba(0, 135, 197, 0.6);
}

.boxed {
  background: white;
  box-shadow: $subtle-shadow;
  padding: 10px;
  border-radius: 10px;
  display: table;
}

.card {
  position: relative;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  transition: 0.75s;
}

//CSS Transitions
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
  transform-style: preserve-3d;
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.slideup-enter {
  opacity: 0;
  transform: tranlsateY(-100px);
  transform-style: preserve-3d;
}
.slideup-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 3000ms, transform 3000ms, translateY(-50px);
  transform-style: preserve-3d;
}
.slideup-exit {
  opacity: 1;
  transform: tranlsateY(-400px);
  transform-style: preserve-3d;
}
.slideup-exit-active {
  opacity: 0;
  transform: tranlsateY(-900px) !important;
  transition: opacity 3000ms, transform 300ms;
  transform-style: preserve-3d;
}

.dialog-enter {
  opacity: 0.01;
  transform: scale(1.1);
}

.dialog-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.dialog-exit {
  opacity: 1;
  transform: scale(1);
  position: absolute;
}

.saved-enter {
  opacity: 0;
  transform: scale(1.1);
}

.saved-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.saved-enter-done {
  animation-delay: 1s;
  animation: holdFade 300ms ease-out;
}

@keyframes holdFade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.success-ani {
  svg {
    width: 50px;
    display: block;
    margin: 0 auto;
  }

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      -webkit-animation: dash 0.9s ease-in-out;
      animation: dash 0.9s ease-in-out;
    }
    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
      animation: dash 0.9s 0.35s ease-in-out forwards;
    }
    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
      animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }
  }

  p {
    text-align: center;
    font-size: 1.25em;
    &.success {
      color: #73af55;
    }
    &.error {
      color: #d06079;
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
}

.add-prompt,
.delete-prompt {
  min-width: 400px;
  padding: 40px 33px;
  h3 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  .qty {
    display: flex;
    align-items: center;
    justify-content: center;
    .dec,
    .inc {
      cursor: pointer;
      user-select: none;
      color: $blue;
      flex: 1;
      font-size: 26px;
      text-align: center;
      font-weight: bold;
    }
  }
}

.address-form {
  .address-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .state,
    .city,
    .postal {
      width: 32%;
    }
    .country {
      width: 100%;
    }
  }
}

.add-address {
  cursor: pointer;
  margin-top: 20px;
  display: grid;
  text-align: center;
  i {
    font-size: 24px;
    color: $blue;
  }
  span {
    font-size: 16px;
  }
}

.account-info {
  background: white;
  box-shadow: $subtle-shadow;
  border-radius: 10px;
  padding: 20px;
  margin-top: 40px;
  .new-form {
    margin-top: 20px;
    padding: 30px;
    border-radius: 10px;
    border: 2px solid $blue;
  }
}

.new-cc-form {
  background: white;
  box-shadow: $subtle-shadow;
  border-radius: 10px;
  padding: 20px;
  .new-form {
    margin-top: 20px;
    padding: 30px;
    border-radius: 10px;
    border: 2px solid $blue;
  }
}

.cc-billing-address {
  margin-top: 0;
}

.server-alert {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 20px;

  p {
    opacity: 0.7;
    margin: 0;
    padding: 0 0 0 10px;
  }
}

.db-active,
.db-inactive {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.db-active {
  background: $green;
}

.db-inactive {
  background: $red;
  animation: pulseAlert 2s infinite;
}

@keyframes pulseAlert {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(2);
  }
}

.card.dotted {
  border: 3px dashed $gray;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  i {
    font-size: 50px;
    color: $lgray;
    margin-right: 20px;
  }
}

.cc-form {
  margin-top: 50px;
}

.card {
  background-color: white;
  box-shadow: $subtle-shadow;
  color: $lgray;
  width: 100%;
  margin: 0 0 20px 0;
  border-radius: 5px;
  padding: 20px 50px 65px;
  .cc-info {
    width: 110px;
  }
  h3 {
    color: $lblack;
  }
  i {
    font-size: 70px;
    color: $lblack;
  }

  .cc-info {
    p {
      margin: 0;
    }
  }
  .flex-container {
    align-items: center;
    justify-content: space-between;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.bottom-nav {
  position: absolute;
  bottom: 0px;
  text-align: left;
  width: 100%;
  left: 0;
  border-top: 1px solid $gray;
  i {
    font-size: 16px;
    padding-right: 10px;
    color: $blue;
  }
  h4 {
    margin: 0;
    font-weight: 500;
    padding: 14px;
  }
}

.content-blocks {
  .flex-container {
    flex-wrap: wrap;
    align-items: stretch;
  }
  .col1,
  .col2,
  .col3 {
    width: 100%;
  }
  .col1 {
    .background-image {
      border-radius: 10px;
      width: 100%;
      height: 48%;
      cursor: pointer;
    }
  }

  .support-block,
  .create-so-block {
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: $subtle-shadow;
    cursor: pointer;
    transition: 0.3s;
    display: block;
    i {
      color: $blue;
    }
    &:hover {
      transform: scale(1.02);
      transition: 0.2s;
    }
  }
  .so-block,
  .to-do {
    border-radius: 10px;
    background: white;
    padding: 20px;
    box-shadow: $subtle-shadow;
  }
  .create-so-block {
    border: 2px solid $blue;
    padding: 43px;
    text-align: center;
    font-size: 18px;
  }
  .support-block {
    background: $blue;
    color: white;
    padding: 30px;
    text-align: center;
  }
  .so-block {
    .row-heads {
      padding: 5px 8px;
    }
  }
}

.home-so {
  padding: 10px;
  box-shadow: none;
  background: $offwhite;
  border: 1px solid $gray;

  .po-number {
    flex-grow: 10;
  }
  .cost {
    flex-grow: 1;
  }
  .status {
    flex-grow: 1;
  }
}

.order-history {
  .line-item {
    padding: 12px;
    transition: 0.2s;
  }
  .row-heads {
    &:after {
      content: "";
      display: flex;
      flex: 1;
      padding: 15px 5px;
    }
  }
  .so-form {
    .row-heads {
      margin-top: 30px;
      &:after {
        display: none;
      }
    }
    button {
      i {
        margin-right: 10px;
      }
    }

    .qty {
      flex: 1;
    }
    .num {
      flex: 6;
    }
    .unit-price {
      flex: 1;
    }
    .price {
      flex: 1;
    }
  }
  .num {
    flex: 1;
  }
  .created {
    flex: 1;
  }
  .tracking {
    flex: 2;
    a {
      background: #bdbdff;
      color: #2a2a82;
      padding: 5px;
      border: none;
    }
  }
  .shipto {
    flex: 1;
    display: none;
  }
  .carrier {
    display: none;
    flex: 1;
  }
  .total {
    flex: 1;
    display: none;
  }
  .status {
    flex: 1;
  }

  .add-to-po {
    flex: 1;
    p {
      color: $black;
    }
  }
}

.loading {
  width: 100%;
  z-index: 999;
  text-align: center;
  p {
    color: $lgray;
  }
  .sub-text {
    margin-top: -50px;
    text-transform: uppercase;
    letter-spacing: 10px;
    color: gray;
  }
  .bloogle {
    display: grid;
    align-content: center;
    justify-content: center;
  }
  .bubble {
    animation: grow 1s;
    margin-left: -3px;
    background: #e6e6e6;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    position: absolute;
  }
  svg {
    width: 30px;
    margin: 0 auto;
    animation: main 2s infinite;
  }

  @keyframes grow {
    from {
      transform: scale(0);
    }
  }

  @keyframes main {
    from {
      transform: rotate(180deg);
    }
  }

  @keyframes red {
    from {
      opacity: 0;
      transform: translate(400px, 400px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes blue {
    from {
      opacity: 0;
      transform: translate(-400px, 400px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes yellow {
    from {
      opacity: 0;
      transform: translate(-400px, 0px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes green {
    from {
      opacity: 0;
      transform: translate(-400px, 0px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  .yellow {
    fill: #fcb916;
    animation: yellow 2s infinite;
  }
  .green {
    fill: #00a550;
    animation: green 2s infinite;
  }
  .red {
    fill: #ef483d;
    animation: red 2s infinite;
  }
  .blue {
    fill: #357fc2;
    animation: blue 2s infinite;
  }
}

.step-nav-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.cat-title {
  margin-right: 50px;

  .cat-top {
    display: table;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    font-weight: bold;
    color: gray;
  }
  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
}

.info-tab {
  display: flex;
}

.recommended-search {
  display: flex;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-left: 20px;
  .tab {
    cursor: pointer;
    margin-right: 20px;
    background: $gray;
    padding: 5px 8px;
    display: table;
    border-radius: 5px;
  }
}

.hide-mobile {
  display: none;
}

.invite-block {
  width: 100%;
  padding: 30px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.subtotal {
  display: flex;
  justify-content: flex-end;
  .container {
    padding: 20px 30px;
    background: white;
    box-shadow: $subtle-shadow;
    h4,
    p {
      margin: 0;
    }
    p {
      font-size: 18px;
      font-weight: bold;
      color: $green;
    }
  }
}

.bottom-step-nav {
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
}

.video-responsive {
  margin: 20px 0;
  position: relative;
  display: block;
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: $subtle;
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }
}

.video-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.flex-content {
  margin-top: 50px;
}

.feed-element {
  background: white;
  box-shadow: $subtle-shadow;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  display: table;
  position: relative;
  .tag-container {
    display: flex;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  iframe {
    position: relative;
    z-index: 999;
  }
  p {
    margin: 0;
  }
  .content {
    font-size: 18px;
    h2 {
      font-weight: normal;
    }
    h2,
    h3,
    h4,
    h5 {
      color: $lgray;
    }
    h1,
    h2,
    h3,
    h3,
    h5 {
      margin: 0;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 1em;
    }
  }
  .tag {
    background: $blue;
    color: white;
    display: table;
    padding: 5px 10px;
    margin-right: 10px;
  }
  .posted {
    color: $lgray;
  }
}

span.MuiButton-label {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
}

.round-robin {
  .num,
  .price,
  .assignee {
    flex: 2;
  }
  .customer {
    flex: 3;
  }
  .add-to-po,
  .buffer,
  .status {
    flex: 1;
  }
}
.verifying {
  display: flex;
  height: 100vh;
  justify-content: center;
  min-height: 768px;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  font-size: 18px;
  .container {
    max-width: 400px;
    margin: 0 auto;
  }
  i {
    font-size: 40px;
    width: 100%;
    --fa-primary-color: #0070e0;
    --fa-secondary-color: gray;
  }
}

.gray {
  color: $gray;
}

.user-search {
  div {
    font-size: 12px;
  }

  .name,
  .email,
  .company {
    flex: 3;
  }
  .fbId,
  .cert,
  .info,
  .agreement {
    flex: 1;
    i {
      font-size: 18px;
    }
  }
  .line-item {
    transition: 0.2s;
  }

  .action {
    cursor: pointer;
    &:hover {
      color: $blue;
    }
  }

  .container {
    padding: 50px;
  }

  .db-inactive {
    animation: none;
  }

  .db-inactive,
  .db-active {
    margin: 0 auto;
  }

  .active {
    width: 10px;
    margin-right: 10px;
  }

  .user {
    flex: 3;
  }

  .action,
  .status,
  .account-level {
    flex: 1;
    text-align: center;
  }

  .loggedin {
    flex: 2;
  }
  .email,
  .company {
    flex: 4;
  }
}

.action-modal-container {
  padding: 70px 100px;
  text-align: center;
  max-width: 700px;

  i {
    font-size: 40px;
    color: $lgray;
  }
}

@media screen and (min-height: 768px) {
  .sidenav-button {
    padding: 5px 0;
    i {
      margin: 0;
    }
    p {
      display: inherit;
    }
  }
}
.post-it {
  font-size: 16px;
  max-width: 380px;
  padding-top: 20px;
}


.order-item {
  .line-item {
    flex-wrap: wrap;
  }
  .highlight {
    font-weight: 600;
    margin-top: 10px;
    width: 100%;
    display: table;
    text-transform: inherit;
    font-size: 1em;
  }
}

#nprogress {
  .bar {
    background: $blue;
    z-index: 99999999;
    height: 4px;
  }
}

.file-uploader {
  border: none !important;
  background-color: $subtle !important;
  padding: 30px 20px;
  border-radius: 5px;

  &:after {
    content: "";
    position: absolute;
    border-radius: 5px;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    border: 2px dashed #7c7eff;
  }
  .MuiChip-label {
    opacity: 0.7;
  }
  p {
    font-size: 18px;

    opacity: 0.7;
    width: 70%;
    margin: 0 auto;
  }
}
.files {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.file-item {
  background: whitesmoke;
  border-radius: 5px;
  margin-bottom: 13px;
  padding: 10px;
  margin-right: 10px;
  border: none;
  &:hover {
    transition: 0.1s;
    background: #b6bdca;
    cursor: pointer;
    transform: scale(1.02);
  }
  i {
    font-size: 16px;
    padding-left: 8px;
    color: $blue;
  }
}

.main-view {
  position: relative;
  min-height: 98vh;
}

.id-note {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 11px;
  color: gray;
  letter-spacing: 1px;
}

.doc {
  background: white;
  box-shadow: $subtle-shadow;
  padding: 10px 30px;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: left;
  strong {
    padding: 10px 0;
    display: block;
  }
  p {
    text-align: inherit;
    padding: inherit;
    opacity: 0.76;
    line-height: 1.5;
  }
  .fine {
    padding-top: 20px;
  }
  button {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .highlight {
    margin: 0 auto;
    margin-top: 5px;
    display: table;
  }
}

.signature-box {
  border: 4px solid $blue;
  padding: 20px;
  text-align: center;
  cursor: pointer;

  .sig {
    font-family: mayence-premium, sans-serif;

    font-size: 40px;
  }
  .sig,
  .pre-signed {
    display: table;
    text-align: center;
    margin: 0 auto;
    border-bottom: 2px solid gray;
  }
  div {
    font-weight: bold;
  }
}

.vh-centered {
  height: 100vh;
  min-height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.chip-array {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  li {
    list-style-type: none;
  }
}
textarea {
  width: 100%;
  min-height: 130px;
  border-radius: 10px;
}

.alert-dialogue {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 20px;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: $subtle-shadow;
  h3 {
    margin: 0;
    font-size: 24px;
  }
  i {
    color: $red;
    font-size: 18px;
  }
}

.new-billing-info {
  background: white;
  padding: 50px;
  margin-top: 150px;
  box-shadow: $subtle-shadow;
  border-radius: 10px;
  max-width: 700px;
  .cc-form {
    margin-top: 0;
  }
}
.image-element-class img {
  width: 100%;
  box-shadow: $subtle-shadow;
}

.two-col {
  .container {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.warranty-info {
  background: white;
  box-shadow: $subtle-shadow;
  padding: 40px 30px 30px 30px;
  position: relative;
  max-width: 620px;
  border-radius: 5px;
  h2 {
    background: $blue;
    display: table;
    color: white;
    padding: 10px;
    position: absolute;
    top: -30px;
    border-radius: 5px;
  }
  .container {
    p {
      opacity: 0.75;
    }
  }
}

.help-info {
  .container {
    div {
      background: white;
      box-shadow: $subtle-shadow;
      padding: 40px 30px 30px 30px;
      position: relative;
      border-radius: 5px;
      text-align: center;
    }
    i {
      font-size: 34px;
      color: $green;
    }
    .btn {
      margin-top: 20px;
    }
    ul {
      list-style-type: none;
      li {
        font-weight: bold;
      }
    }
  }
}

.background-image {
  position: relative;
  background-size: cover;
  background-position: center;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lightbox {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.5);
  i {
    position: absolute;
    color: white;
    top: 50px;
    right: 50px;
    font-size: 24px;
    cursor: pointer;
  }
  .container {
    width: 90%;
    margin: 0 auto;
  }
}
.download-box {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  .item {
    width: 100%;
    margin: 7px;
  }
}

.notif {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  position: relative;
  p {
    font-size: 18px;
  }
  h2 {
    font-size: 32px;
    color: $blue;
  }
  .icon-flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      i {
        opacity: 0.8;
        width: 100%;
        padding-bottom: 10px;
        font-size: 24px;
      }
    }
  }
  .container {
    width: 400px;
  }
  .far {
    font-size: 34px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-image {
  background-size: cover;
  background-position: center;
}
.override-input {
  padding: 15px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
}

.media-library {
  .success {
    text-align: center;
    p {
      display: none;
    }
  }
  .upload-container {
    display: grid;
    align-items: center;
    img {
      height: inherit;
    }
  }
}

.makeStyles-paper-22:focus {
  outline: none !important;
}

.delete-prompt {
  text-align: center;
  h2 {
    font-size: 2em;
    margin: 0;
  }
  ul {
    padding: 0;
    text-align: center;
  }
  li {
    margin: 0 auto;
    list-style-type: none;
    background: $subtle;
    color: $lgray;
    border-radius: 5px;
    padding: 8px 10px;
    display: table;
  }
}

.invite-center {
  padding-bottom: 50px;
  width: 100%;
  .line-item {
    border: 1px solid $gray;
    padding: 10px 20px;
    margin: 7px 0;
    .delete.Mui-checked {
      color: $red;
    }
  }
  .row-heads {
    align-items: center;
    margin-bottom: 10px;
  }
}

.invite-header {
  text-align: center;
  padding-top: 30px;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 40px;
  max-width: 400px;
  p {
    color: $darkModeLightText;
  }
  h1,
  p {
    margin: 0;
  }
  h1 {
    padding-bottom: 10px;
    color: $blue;
  }
}

.invite-row {
  padding: 5px 20px;
}
.invite-container {
  padding: 10px;
  max-width: 600px;
  margin: 0 auto;

  .invite-email {
    width: 55%;
    font-size: 0.9em;
  }
  .invite-radio {
    width: 10%;
    margin: 0 10px;
    padding: 0;
  }
}

.invite-add-new {
  background: white;
  box-shadow: $subtle-shadow;
  padding: 15px;
  color: white;
  text-align: center;
  border-radius: 10px;
  .add-new {
    color: white;
    background: $blue;
  }
  h2,
  p {
    margin: 0;
    text-align: center;
  }
  h2 {
    color: $blue;
  }
  p {
    padding: 15px 0;
    color: $lgray;
  }
  input {
    background: white;
  }
}

.fixture-profiles {
  h4 {
    cursor: pointer;
    i {
      padding-right: 10px;
    }
  }
  .line-item {
    padding: 10px;
  }
  .name {
    cursor: pointer;
  }
  .download {
    cursor: pointer;
    color: $lgray;
    align-items: center;
    i {
      color: $blue;
      padding-left: 5px;
    }
  }
  .container {
    padding: 40px;
    .folder {
      box-shadow: $subtle-shadow;
      background: white;
      padding: 10px;
      text-align: center;
      transition: 0.1s ease-in-out;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
      h3 {
        font-size: 16px;
        font-weight: normal;
      }

      i {
        font-size: 65px;
        color: #d4dae0;
      }
    }
    .grid-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(359deg);
  }
}


.bar {
  top: 0;
  position: fixed;
  width: 100%;
  background: #e0e6eb;
  height: 10px;
  left: 0;
  z-index: 9999;
  .progress {
    background: #3dd3df;
    height: 100%;
    width: 0%;
    transition: 0.6s cubic-bezier(0.33, 1, 0.68, 1);
  }
}

.form-nav {
  margin: 0 auto;
  margin-top: 20px;
  width: 500px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 50px;
}

.add-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    cursor: pointer;
    &:hover {
      color: $red;
    }
  }
}

.new {
  border: 1px solid #c6c6c6;
  padding: 18px 0;
  border-radius: 8px;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: $green;
    transition: 0.2s;
    color: white;
  }
}

input.ais-SearchBox-input {
  width: 100%;
  padding: 17px;
  border: none;
  color: gray;
  font-size: 16px;
  margin-top: 20px;
  box-shadow: $subtle-shadow;

  &:focus {
    outline: none;
  }
}

form.ais-SearchBox-form {
  display: flex;
  align-items: center;
}

button.ais-SearchBox-reset {
  display: none;
}

button.ais-SearchBox-submit {
  background: transparent;
  border: none;
  margin-top: 29px;
}

button.ais-SearchBox-submit svg {
  width: 21px;
  height: 20px;
}

ul.ais-Hits-list {
  list-style-type: none;
  padding: 0;
}

ul.ais-Pagination-list {
  display: flex;
  align-items: center;
  list-style-type: none;
  justify-content: center;
}

li.ais-Pagination-item {
  font-size: 19px;
  margin: 10px;
  a {
    border: none;
  }
}

a.ais-Pagination-link.ais-Pagination-link--selected {
  background: $blue;
  color: white;
  padding: 0 12px;
  border-radius: 4px;
}

.new-post {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  display: table;
}

.post-tools {
  right: 15px;
  top: 15px;
  position: absolute;
  i {
    cursor: pointer;
    color: $lgray;
    &:hover {
      color: $red;
    }
  }
}

.set-new-post {
  cursor: pointer;
  border: 1px solid $blue;
  padding: 20px;
  display: table;
  border-radius: 8px;
  color: $lgray;
  font-size: 18px;
  margin-bottom: 30px;
  &:hover {
    color: white;
    background: $blue;
  }
}

div[class*="MuiDropzoneArea-active"] {
  opacity: 0.7;
  z-index: 1;
}

.prod-editor-view {
  padding-top: 30px;
  input::placeholder {
    color: black;
    opacity: 1 !important;
  }
}

#mui-rte-editor {
  border-top: 1px solid $gray;
  padding: 15px 0 15px 15px;
}

.filled-container {
  box-shadow: $subtle-shadow;
  padding: 20px;
  background: white;
  margin-bottom: 50px !important;
}

.snack-alert {
  background: $green;
}

.editor-icon {
  color: $blue;
  padding-left: 10px;
  font-size: 1.3em;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;

  img.selected-image {
    transition: 0.2s ease-in-out;
    transform: scale(0.98);
    box-shadow: $subtle-shadow;
    background: #d6d6d6;
  }

  img {
    width: 100%;
    padding: 5;
    margin: 10px;
    transition: 0.1s ease-in-out;
    background: white;
    cursor: pointer;
  }
}

/* Style your items */

.load-3 .line:nth-last-child(1) {
  animation: loadingC 0.6s 0.1s linear infinite;
}
.load-3 .line:nth-last-child(2) {
  animation: loadingC 0.6s 0.2s linear infinite;
}
.load-3 .line:nth-last-child(3) {
  animation: loadingC 0.6s 0.3s linear infinite;
}

.load-wrap {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 30px;
  margin: 10px;

  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: $blue;
  }
}

@keyframes loadingC {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.enlarged-toolbar {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #a0a0a0;
  font-size: 19px;
  opacity: 0.7;
  display: flex;
  .fa-star.active {
    color: #e09202;
  }

  .fa-star {
    &:hover {
      color: #e09202;
    }
  }

  .fa-download {
    &:hover {
      color: $blue;
    }
  }
  .h1-icon,
  i {
    margin-right: 10px;
    background: #d7d7d7;
    width: 40px;
    height: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
  }
}

.h1-icon.active {
  background: $blue;
  color: white;
}

i#image-star {
  color: #e09202;
  position: absolute;
  left: 35px;
  top: 32px;
  z-index: 9;
}

.download-selected {
  position: fixed;
  bottom: 0;
  width: 98%;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  right: 0;
  margin: 0 auto;
  z-index: 99;
}

.image-modular {
  .close {
    color: white;
  }
}

.pulse {
  animation: grow 1.5s infinite;
}

.editor-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #5e6fab;
}

figure.table {
  margin: 0;
  padding-top: 29px;
}

.sidenav-a {
  color: inherit;
  display: block;
  border-bottom: none;
}

// .profile-editor {
//   padding-top: 47px;
//   background-color: #03070b;

//   .profile-nav {
//     min-height: 75px;
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     z-index: 1000000;
//     width: 100%;
//     background: #09151e;
//     display: flex;
//     justify-content: space-between;
//     .flex-container {
//       display: flex;
//       align-items: center;
//       width: 95%;
//       margin: 0 auto;
//       justify-content: space-between;
//       .profile-info {
//         display: flex;
//       }
//     }
//     .nav-item {
//       padding: 1em 2em;
//       border-right: 1px solid #06080a;
//       h5 {
//         color: #73787e;
//         font-weight: normal;
//         margin: 0;
//         padding-top: 10px;
//       }
//       p {
//         color: white;
//         font-size: 16px;
//         margin: 0;
//         padding: 0;
//       }
//     }
//     svg {
//       fill: $blue;
//     }
//     label {
//       color: #73787e;
//     }
//     input {
//       color: white;
//     }
//   }
//   .collapsed {
//     width: 0% !important;
//   }
//   .profile-col::-webkit-scrollbar {
//     display: none;
//   }
//   .fixed-col-head {
//     height: 3em;
//     top: 1.25em;
//     width: 100%;
//     z-index: 2;
//     position: fixed;
//     display: flex;
//     align-items: center;
//   }
//   .active-col {
//     height: 90vh;
//   }
//   .profile-col {
//     height: 100vh;
//     position: relative;
//     overflow-y: scroll;
//     -ms-overflow-style: none; /* IE and Edge */
//     scrollbar-width: none; /* Firefox */
//     border-left: 1px solid #08131b;
//     border-top: 1px solid #08131b;
//     h3 {
//       color: #73787e;
//       font-weight: normal;
//       font-size: 1.5em;
//       padding-left: 1.5em;
//     }
//   }
//   .pc-1 {
//     background: #010407;
//     padding-top: 4.5em;
//     .MuiFilledInput-underline:after {
//       border-bottom: none;
//     }
//     label {
//       color: #73787e !important;
//     }
//   }
//   .pc-2 {
//     background: #03070b;
//     padding-top: 5em;
//   }
//   .pc-3 {
//     background: #03070b;
//     padding-top: 5em;
//   }

//   .profile-option {
//     cursor: pointer;
//     font-size: 16px;
//     border-bottom: 1px solid #08131b;
//     width: 100%;
//     padding: 0.5em 0;
//     .profile-button {
//       color: white;
//       min-width: 300px;
//       display: table;
//       margin-left: 1em;
//       border: 1px solid rgba(255, 255, 255, 0.322);
//       padding: 10px 20px;
//       border-radius: 50px;
//       i {
//         color: $blue;
//       }
//     }
//     .profile-checkboxes {
//       padding-left: 1em;
//       color: white;
//       svg {
//         fill: $blue;
//       }
//     }
//     .arrow {
//       transform: rotate(0deg);
//       transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
//     }

//     .active {
//       transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
//       transform: rotate(90deg);
//     }
//   }
// }
// .profile-adder {
//   background: $subtle;
//   padding: 2em;
//   width: 650px;
// }
.btn-group-dynamic {
  display: flex;
  align-items: baseline;
}
// .fixture-search {
//   .search-results {
//     max-height: 200px;
//     overflow-y: scroll;
//     .line-item {
//       padding: 20px;
//       font-size: 16px;
//       .fa-trash-alt {
//         color: $red;
//       }
//       .fa-edit {
//         color: $neutral;
//       }
//       i {
//         font-size: 18px;
//         padding-left: 2em;
//       }
//     }
//   }
// }

// .profile-channel.active {
//   background: #09151e;
// }
// .profile-channel {
//   padding-left: 2em;
//   align-items: center;
//   border-bottom: 1px solid #08131b;
//   color: white;

//   .profile-order {
//     text-align: center;
//     color: #73787e;
//     font-size: 1.5em;
//     padding-right: 1em;
//   }
//   .channel-name {
//     display: flex;
//     align-items: center;
//     font-size: 1.5em;
//   }
// }

// .trip-text {
//   text-align: center;
//   text-transform: uppercase;
//   font-weight: bold;
//   font-size: 3em;
//   letter-spacing: 14px;
//   text-shadow: -5px -4px 0px #009cff99, 2px 4px #ff0045c7;
// }

// #channel-name-edit,
// #new-profile-name {
//   color: white !important;
//   border: none;
// }
// #segment-name-edit {
//   background: none;
//   border: none;
//   font-size: 16px;
//   text-align: center;
//   &:focus-visible {
//     outline: none !important;
//   }
// }

// #available-channels {
//   color: white !important;
// }

// .bare-text-input {
//   border: none;
//   background: transparent;
//   outline: none !important;
// }

// .iris-quote-builder {
//   .search-bar {
//     width: 30%;
//     display: flex;
//     align-items: center;
//     margin: 20px 10px 50px 0;
//     min-width: 300px;
//     input {
//       width: 100%;
//       padding: 10px 20px;
//       border-radius: 5px;
//       border: 1px solid #dedede;
//     }
//     i {
//       margin-left: 10px;
//     }
//   }
//   .flex-container {
//     display: flex;
//   }

//   .save-modal-flex {
//     padding: 50px;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     div {
//       width: 90%;
//     }
//   }

//   .save-modal-button {
//     display: flex;
//     justify-content: space-between;
//   }

//   .product-settings {
//     h2 {
//       font-size: 30px;
//       margin-top: 30px;
//     }
//     p {
//       border-bottom: 1px solid #dedede;
//       padding-bottom: 20px;
//       color: $gray;
//       font-size: 16px;
//     }
//   }
//   .processing {
//     span {
//       color: whitesmoke;
//       margin-left: 5px;
//     }
//     div {
//       color: $gray;
//       font-size: 0.9em;
//     }
//     margin-bottom: 30px;
//   }

//   .warning,
//   .success {
//     font-weight: bold;
//   }

//   .warning {
//     color: red;
//   }
//   .success {
//     color: green;
//   }

//   .settings {
//     min-height: 768px;
//     position: relative;
//     .flex-container {
//       height: 100%;
//     }
//     .left {
//       max-width: 250px;
//       min-width: 170px;
//       width: 30%;
//     }
//     .launch-view {
//       .container {
//         padding: 50px 100px;
//       }
//     }
//     .launch-pad {
//       position: fixed;
//       top: 0;
//       height: 100%;
//       width: 30%;
//       padding: 20px;
//       max-width: 250px;
//       min-width: 170px;
//       background: #111;
//       padding-top: 60px;
//       .active {
//         transform: translateX(10px);
//         i {
//           color: $blue;
//         }
//       }
//       div {
//         cursor: pointer;
//         transition: 0.2s ease-in-out;
//         padding: 10px 0;
//         color: white;
//         &:hover {
//           transition: 0.2s ease-in-out;
//           transform: translateX(10px);
//           i {
//             color: $blue;
//           }
//         }
//       }
//       i {
//         color: gray;
//         padding-right: 10px;
//       }
//     }
//   }

//   .btn {
//     color: white;
//     cursor: pointer;
//     text-align: center;
//     display: block;
//     padding: 10px 25px;
//     &:hover {
//       filter: brightness(90%);
//     }
//   }

//   .btn.blue {
//     background: $blue;
//     &:hover {
//       color: white;
//     }
//   }

//   .btn.otl {
//     border: 1px solid #cecece;
//     color: $black;
//   }

//   .btn.red {
//     background: #ea3b53;
//   }

//   .item-settings {
//     background: #ebecf5;
//     padding: 50px;
//     border-radius: 5px;
//     margin: 25px 0;
//     .row {
//       width: 100%;
//       display: flex;
//       div {
//         flex-grow: 1;
//         margin-right: 20px;
//         &:last-child {
//           margin-right: 0px;
//         }
//       }
//     }
//   }

//   .radio-group {
//     flex-direction: row !important;
//     padding-bottom: 20px;
//     span {
//       color: #929090;
//       font-size: 13px;
//     }
//   }

//   .radio-input-group {
//     margin: 40px 0 0;
//   }

//   .label-flex {
//     display: inline-flex;
//     font-size: 13px;
//     color: gray;
//     padding: 10px 0;
//   }

//   .ratio-inputs {
//     display: flex;
//     align-items: baseline;
//     width: 150px;
//     span {
//       font-weight: bold;
//       padding: 0 20px;
//     }
//   }

//   .button-two {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     max-width: 240px;
//     .reset-dim {
//       cursor: pointer;

//       &:hover {
//         opacity: 1;
//       }
//     }
//   }

//   .branding {
//     display: flex;
//     flex-grow: 1;
//     align-items: center;
//     .account-type {
//       color: gray;
//       font-size: 14px;
//       top: 3px;
//       position: relative;
//     }
//   }

//   p.label {
//     font-size: 11px;
//     margin-top: 10px;
//   }
//   .not-logged-in {
//     height: 90vh;
//     min-height: 768px;
//     display: flex;
//     flex-wrap: wrap;
//     align-content: center;
//     justify-content: center;
//     h3 {
//       width: 100%;
//       text-align: center;
//     }
//   }

//   .calc-button {
//     margin: 30px 0;
//   }

//   .welcome-login {
//     padding-bottom: 30px;
//     text-align: center;
//     h2 {
//       font-size: 1.5em;
//     }
//   }

//   .header {
//     position: fixed;
//     background: black;
//     box-shadow: 5px 2px 20px 0px rgba(0, 0, 0, 0.3);
//     color: #efefef;
//     width: 100%;
//     padding: 0 10px;
//     z-index: 99999999999999;
//     display: flex;
//     align-items: center;
//     .branding {
//       .logo {
//         width: 130px;
//         padding: 10px;
//         height: auto;
//         display: block;
//       }
//     }
//   }

//   .measure {
//     font-size: 12px;
//   }

//   .quote-form {
//     .columns {
//       border-bottom: 1px dotted rgb(31, 31, 31);
//     }
//     .discount {
//       font-size: 0.75em;
//       color: #474b4e;
//       padding-left: 3px;
//       font-weight: bold;
//     }
//   }
//   .is-outlined {
//     background: transparent;
//     color: white;
//     &:hover {
//       background: $blue;
//       color: white;
//       border-color: $blue;
//     }
//   }

//   .panel-selector .active {
//     margin-top: -20px;
//     background: $black;
//     padding: 10px;
//     border-radius: 10px;
//     transition: 0.3s;
//   }
//   .panel-selector {
//     border-top-right-radius: 5px;
//     position: absolute;
//     top: -37px;
//     left: 0px;
//     background: rgb(42, 35, 153);
//     background: linear-gradient(0deg, #2022b4 0%, #282bf6 28%);
//     padding: 10px;
//     display: flex;
//     justify-content: space-between;
//     width: 180px;
//     i {
//       font-size: 18px;
//       cursor: pointer;
//       &:hover {
//         color: white;
//       }
//     }
//   }

//   .btn-add {
//     width: 100%;
//     text-align: center;
//     display: table;
//     background: $blue;
//     padding: 2px 0;
//     margin-top: 12px;
//   }
//   .add-container {
//     display: inline-flex;
//     flex-wrap: wrap;
//     align-items: center;
//     justify-content: space-between;
//   }
//   .input-halves {
//     display: flex;
//     justify-content: space-between;

//     // div[class*="MuiFormControl"] {
//     //   width: 40% !important;
//     // }
//   }

//   .dialog-container {
//     width: 400px;
//     padding: 50px;
//     text-align: center;

//     i {
//       cursor: pointer;
//       font-size: 22px;
//       margin-left: 10px;
//       &:hover {
//         color: $blue;
//       }
//     }
//     input {
//       padding: 12px;
//       margin: 10px 0 23px;
//     }
//     .is-size-9 {
//       color: $blue;
//     }
//     .columns {
//       display: flex;
//       justify-content: center;
//       margin-top: 20px;
//     }
//   }

//   .dash-bar {
//     z-index: 9000;
//     position: absolute;
//     top: 17px;
//     right: 20px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }

//   .fine {
//     font-size: 12px;
//   }

//   .view-quote {
//     background: $blue;
//     color: white;
//     border-radius: 25px;
//     padding: 8px 15px;
//     margin-right: 20px;
//     transition: 0.3s;
//     cursor: pointer;
//     &:hover {
//       filter: brightness(110%);
//       transition: 0.3s;
//     }
//   }

//   .dash-icons {
//     background: #eaeaea;
//     padding: 10px 15px;
//     border-radius: 25px;
//     display: flex;
//     justify-content: space-between;
//     font-size: 1.2em;
//     align-items: center;
//     i {
//       margin: 0 10px;
//       cursor: pointer;
//       color: $gray;
//       &:hover {
//         color: $blue;
//       }
//     }
//   }

//   //saved data

//   .save-database {
//     background: $white;
//     color: gray;
//     border-radius: 5px;
//     select#user-switch {
//       width: 80%;
//       padding: 5px;
//       border-radius: 6px;
//       background: #d1d5e5;
//       color: black;
//     }
//     i {
//       cursor: pointer;
//       color: $red;
//     }
//   }

//   .top-sort {
//     width: 92%;
//     padding-left: 34px;
//     text-transform: uppercase;
//     letter-spacing: 2px;
//     font-size: 12px;
//     font-weight: 800;
//     margin-bottom: 0px !important;
//     .column {
//       padding: 0;
//       display: flex;
//     }
//   }

//   .sorter {
//     i {
//       padding-left: 10px;
//       font-size: 16px;
//     }
//   }
//   .divider {
//     border-bottom: 1px solid lightgray;
//     padding: 10px 0;
//   }

//   span.save-title {
//     text-transform: uppercase;
//     font-size: 0.7em;
//     font-weight: bold;
//     letter-spacing: 2px;
//     color: #b1b1b1;
//   }

//   .view-controls {
//     display: flex;
//     flex-wrap: wrap;
//   }

//   .save-box {
//     width: 98%;
//     margin: 0 auto;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     background: white;
//     margin: 10px 0;
//     padding: 0 10px;
//     border-radius: 10px;
//     box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
//     .column {
//       padding: 10px 0;
//     }
//     .columns {
//       width: 90%;
//       margin: 0;
//     }
//   }

//   .save-ui {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }

//   .load {
//     padding: 10px;
//     background: lightgreen;
//     color: black;
//     border-radius: 5px;
//     margin-right: 20px;
//   }

//   //contact form

//   .contact-form {
//     max-width: 600px;
//     margin: 0 auto;
//     background: white;
//     padding: 100px;
//     position: relative;
//     width: 90%;
//   }

//   .active-auth,
//   .inactive-auth {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     &:first-child {
//       border-right: 1px solid rgb(211, 211, 211);
//     }
//   }

//   .inactive-auth {
//     cursor: pointer;
//     background: whitesmoke;
//     transition: 0.4s;
//     border-bottom: 1px solid rgb(211, 211, 211);

//     h2 {
//       color: rgb(211, 211, 211);
//     }
//   }

//   .zoom-controls {
//     position: absolute;
//     display: grid;
//     z-index: 6;
//     top: 9px;
//     i {
//       background: #eaeaea;
//       padding: 15px;
//       cursor: pointer;
//       transition: 0.2s;
//       &:hover {
//         background: $blue;
//         color: white;
//       }
//       &:last-child {
//         margin-top: 5px;
//         border-bottom-right-radius: 10px;
//       }
//     }
//   }

//   .navbar-item {
//     padding: 0.8rem 0.75rem !important;
//     color: $white !important;
//     &:hover {
//       background: $blue !important;
//     }
//     &:focus {
//       background-color: $blue !important;
//     }
//   }

//   .welcome-modal {
//     position: absolute;
//     background: rgba(0, 0, 0, 0.7);
//     width: 100%;
//     height: 100%;
//     z-index: 99999999999;
//     top: 0;
//     left: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     label span {
//       color: #545454 !important;
//     }
//     .dialog-content {
//       overflow: scroll;
//       background: #d2d2d2;
//     }
//     form {
//       color: black;
//       opacity: 0.75;
//       width: 90%;
//       margin: 0 auto;
//       display: flex;
//       flex-wrap: wrap;
//       ul.inputs-list.multi-container {
//         display: flex;
//         justify-content: space-between;
//         width: 67%;
//         min-width: 340px;
//       }
//       .hs_your_comment.hs-your_comment.hs-fieldtype-textarea.field.hs-form-field {
//         display: none;
//       }
//       .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
//         width: 100%;
//       }
//       .hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field,
//       .hs_lastname.hs-lastname.hs-fieldtype-text.field.hs-form-field {
//         width: 50%;
//       }
//       select {
//         padding: 10px;
//         min-width: 290px;
//       }

//       label.hs-error-msg {
//         font-size: 14px;
//         opacity: 0.6;
//       }
//       .input {
//         border: 0;
//         box-shadow: none;
//         margin: 0;
//         padding-left: 0;
//       }
//       input {
//         width: 100%;
//         padding: 10px;
//       }
//       label {
//         text-align: left !important;
//         display: block;
//         margin-top: 5px;
//         padding-bottom: 12px;
//       }
//       .hs_submit.hs-submit {
//         width: 100%;
//         margin-top: 10px;
//         input {
//           background: $blue;
//           color: white;
//           border: none;
//           font-size: 16px;
//         }
//       }
//     }
//     .dialog-content {
//       width: 90%;
//       max-width: 580px;
//       position: relative;
//       border-radius: 10px;
//       background: white;
//       i {
//         z-index: 9999;
//         cursor: pointer;
//         position: absolute;
//         right: 20px;
//         top: 20px;
//         font-size: 26px;
//       }
//       h3 {
//         color: gray;
//         opacity: 0.8;
//         padding-bottom: 20px;
//         font-size: 1.2em;
//       }
//       .main-content {
//         min-height: 140px;
//         display: flex;
//         flex-wrap: wrap;
//         align-items: center;
//         padding: 30px;
//         .btn-group {
//           display: flex;
//           justify-content: space-between;
//           margin: 0 auto;
//           margin-bottom: 30px;
//           width: 290px;
//           align-items: center;
//         }
//       }
//       h1 {
//         font-size: 28px;
//         margin-top: 11px;
//         color: $black;
//       }
//       .top-welcome {
//         height: 120px;
//         background-size: cover;
//         border-radius: 10px 10px 0 0;
//         display: flex;
//         align-items: center;
//         width: 100%;
//         position: relative;
//       }
//       img {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//       }
//     }
//   }

//   .login-container {
//     background: #333;
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-content: center;
//     min-height: 768px;
//     flex-wrap: wrap;
//     img {
//       width: 150px;
//       display: block;
//       margin: 0 auto;
//       margin-bottom: 30px;
//     }
//   }
//   .start-mode {
//     top: 111px;
//   }
//   .dim-mode {
//     top: 173px;
//   }
//   .dialog {
//     z-index: 12;
//     background: #282bf6;
//     width: 216px;
//     padding: 20px;
//     font-size: 1em;
//     position: absolute;
//     left: 375px;
//     box-shadow: 3px 4px 20px 5px rgba(0, 0, 0, 0.14);
//     h3 {
//       color: white;
//       margin: 0;
//       padding: 0;
//       padding-bottom: 0.5em;
//     }
//     p {
//       color: white;
//       opacity: 0.6;
//       margin: 0;
//       padding: 0;
//     }
//     &:before {
//       content: "";
//       position: absolute;
//       left: -20px;
//       width: 0;
//       height: 0;
//       border-right: 20px solid #282bf6;
//       border-bottom: 20px solid transparent;
//       top: 0;
//     }
//   }

//   .mobile-alert {
//     position: fixed;
//     width: 100%;
//     height: 100vh;
//     z-index: 199999;
//     background: white;
//     .container {
//       max-width: 370px;
//       margin: 0 auto;
//       display: flex;
//       align-items: center;
//       height: 100vh;
//       min-height: 768px;
//       flex-wrap: wrap;
//       text-align: center;
//     }
//     h3 {
//       font-size: 28px;
//       padding-bottom: 10px;
//     }

//     p {
//       font-size: 18px;
//       color: gray;
//     }
//   }

//   .form-container.login-form {
//     width: 100%;
//     .chooser {
//       position: absolute;
//       width: 100%;
//       top: 14px;
//       left: 13px;
//       h2 {
//         font-size: 1.1em;
//         color: gray;
//         text-align: center;
//         padding: 0;
//       }
//     }
//   }

//   p.form-error {
//     color: red;
//     padding-top: 30px;
//   }

//   .dim-error {
//     color: #ff6464;
//     font-size: 14px;
//     font-weight: bold;
//     padding: 10px 0;
//   }

//   .calc::-webkit-scrollbar {
//     background: #333;
//   }
//   .calc::-webkit-scrollbar-button {
//     background: #222;
//   }
//   .calc::-webkit-scrollbar-thumb {
//     background: #666;
//   }
//   .calc::-webkit-scrollbar-corner {
//     background: red;
//   }

//   .is-primary {
//     background: $blue !important;
//   }

//   .home {
//     overflow: hidden;
//     height: 100vh;
//     min-height: 768px;
//     display: flex;
//     padding-top: 44px;
//     .calc {
//       width: 100%;
//       background: rgb(19, 19, 19);
//       color: $white !important;
//       .inner {
//         form {
//           .form-section {
//             margin: 0 0 10px 0;

//             h3 {
//               text-transform: uppercase;
//               font-weight: 800;
//               margin: 0;
//               font-size: 0.8em;
//               color: #e4e4e4;
//               letter-spacing: 3px;
//             }
//             &:last-of-type {
//               border-bottom: none;
//             }
//           }
//         }
//       }
//     }

//     .columns {
//       display: flex;
//       justify-content: space-between;
//       padding: 0.75em;
//     }

//     .input-styles,
//     .addon-selectors {
//       padding-top: 2em;
//       .select-override {
//         color: white;
//         &:before {
//           border-bottom: 1px solid $gray;
//         }
//       }
//       svg {
//         fill: $blue;
//       }
//       .is-primary,
//       .is-outlined {
//         width: 47%;
//       }
//       p {
//         color: $gray;
//       }

//       label {
//         color: $gray;
//       }

//       div:contains-selector(label) {
//         width: 100%;
//       }
//       input {
//         color: white;
//         width: 100%;
//         border-bottom: 1px solid $gray !important;
//       }
//     }

//     .view-info.active {
//       transition: 0.3s ease-in-out;
//       bottom: -100% !important;
//     }

//     .reset {
//       z-index: 99999999;
//       position: relative;
//     }
//     .view {
//       cursor: move;
//       width: 100%;
//       position: relative;
//       background-color: white;

//       .view-info {
//         transition: 0.3s ease-in-out;
//         cursor: initial;
//         background: black;
//         position: absolute;
//         z-index: 10;
//         bottom: 0;
//         width: 100%;
//         padding: 20px;
//         color: rgb(255, 255, 255);
//         .info-item {
//           color: #f0f6ff;
//           padding-right: 7px;
//           font-weight: normal;
//           font-size: 13px;
//           margin-bottom: 7px;
//         }
//         p {
//           font-weight: bold;
//           margin: 0;
//           padding: 5px;
//           font-size: 17px;
//           border-bottom: 1px solid rgb(60, 120, 206);
//           display: flex;
//           justify-content: space-between;
//         }
//       }

//       .dimBox.active {
//         opacity: 1;
//         transform: translateX(0%);
//       }
//       .dimBox {
//         border-top-left-radius: 5px;
//         position: absolute;
//         background: $black;
//         padding: 10px 30px 30px;
//         top: -189px;
//         right: 0;
//         transform: translateX(100%);
//         transition: 0.5s ease-in-out;
//         opacity: 0;
//         h3 {
//           background: $blue;

//           width: 100%;
//           font-size: 12px;
//           letter-spacing: 2px;
//           text-transform: uppercase;
//           font-weight: bold;
//           padding: 14px;
//           text-align: center;
//         }
//       }
//       .view-body {
//         .active {
//           transition: 0.3s ease-in-out;
//           transform: translateY(200%);
//         }
//         div {
//           overflow: visible !important;
//         }

//         .gridlines {
//           // border-left: 2px dotted $gray;
//           // border-bottom: 2px dotted $gray;
//           width: 110%;
//           height: 110%;
//           position: absolute;
//           left: -5%;
//           right: 0;
//           top: 50%;
//           transform: translateY(-50%);
//           .width-tag,
//           .height-tag {
//             position: absolute;
//             font-size: 25px;
//             font-weight: bold;
//             color: $lgray;
//           }
//           .width-tag {
//             position: absolute;
//             bottom: -70px;
//             left: 45%;
//           }
//           .height-tag {
//             transform-origin: top left;
//             transform: rotate(-90deg);
//             top: 60%;
//             left: -80px;
//           }
//         }

//         .videoWrapper {
//           position: relative;
//           padding-bottom: 56.25%; /* 16:9 */
//           padding-top: 25px;
//           height: 0;
//         }
//         .yt-wrapper {
//           width: 100%;
//           height: 100%;
//           position: absolute;
//           overflow: hidden !important;
//         }
//         .yt-render {
//           position: absolute;
//           z-index: 0;
//           transform: scale(4);
//           width: 100%;
//           top: 30%;
//           iframe {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//           }
//         }

//         #view-render {
//           position: absolute;

//           .panel-row {
//             width: 100%;
//             background-repeat: space;
//             background-size: 49px;
//             mix-blend-mode: overlay;
//           }
//           .panel-blank {
//             text-align: center;
//             border: 1px solid lighten(#222, 5%);
//             border-radius: 10px;
//             margin: auto;
//             left: 0;
//             right: 0;
//             width: 60%;
//             padding: 120px 60px;
//             p {
//               margin: 0;
//             }
//           }
//         }
//       }
//     }
//   }

//   .fade-left {
//     transform: translateX(-200%);
//     position: absolute;
//     width: 87%;
//     transition: 0.5s ease-in-out;
//   }

//   strong {
//     color: white;
//   }

//   .blue {
//     background: $blue;
//     border-radius: 0px;
//   }

//   .form-group {
//     display: flex;
//     justify-content: space-between;
//   }
// }

.iris-section {
  padding: 2em;
  background: #d2d2d2;
}

.error-boundary {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  text-align: center;
  min-height: 678px;
}

.log-list {
  li {
    list-style: none;
    color: #222;
    padding: 8px 0;
    display: block;
    border-bottom: 1px solid rgb(172, 172, 172);
  }
}

span.sub-line-item {
  display: block;
  font-size: 0.95em;
  color: gray;
}

img.panel-product {
  width: 50px;
}

.panel-col-item {
  display: flex;
  margin: 1px;
}

.panel-row-item {
  display: flex;
}

.infobox {
  background: $gray;
  padding: 15px;
  border-radius: 8px;
  margin-top: 30px;
  h3 {
    margin-top: 5px;
  }
}

.line-item-no-flex {
  margin-bottom: 15px;
  padding: 2em;
  justify-content: space-between;
  box-shadow: $subtle-shadow;
  border-radius: 5px;
  background: white;
}

.editor-save-bar {
  display: flex;
  justify-content: flex-end;
}

.order-pos {
  width: 40px;
  display: flex;
  justify-content: center;
  margin-left: 20px;
}

#pricing-list {
  .selected-item {
    flex: 1;
  }
  .name {
    flex: 10;
  }

  .price {
    flex: 1;
  }
  .line-item {
    flex-wrap: wrap;
    padding: 10px;
    .price {
      display: flex;
      align-items: center;
      .highlight {
        margin-left: 1em;
      }
    }

    .line-item-variants {
      margin-top: 1.5em;
      width: 100%;
      .line-item {
        background-color: #e3e7eb;
        border: 1px solid gray;
      }
    }
  }
}

input.debounce-input {
  padding: 1.2em;
  background: transparent;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
}

.perm-col {
  flex: 1;
  border-right: 1px solid gray;
}

.perm-checkbox {
  border-bottom: 1px solid gray;
}

.customer-sort {
  .num {
    flex: 1;
  }
  .media {
    flex: 4;
  }
  .price {
    flex: 4;
  }
}

.warranty-center {
  h4 {
    margin: 0;
    color: #3e4a52;
    font-weight: 600;
  }
  .info-form {
    background: white;
    padding: 2em;
    border-radius: 8px;
  }
  .button-group {
    display: flex;
    padding-top: 20px;
    justify-content: flex-end;
  }
  .flex-container {
    display: flex;
    height: 100%;
    width: 95%;
    max-width: 960px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }
  .form-container {
    width: 100%;
  }
}

.progress-bar {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  width: 100%;
  .progress-icon.active {
    transition: 0.2s ease-in-out;

    p,
    i {
      opacity: 1;
    }
  }
  .progress-icon {
    width: 20%;
  }
  .progress-icons {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
      font-size: 13px;
    }
    p,
    i {
      transition: 1200ms cubic-bezier(0.22, 1, 0.36, 1);
      opacity: 0.2;
      color: white;
    }
    p {
      text-align: center;
    }
    i {
      font-size: 2em;
    }
  }
}

.progress-tube {
  height: 15px;
  width: 100%;
  border-radius: 25px;
  background-color: #192732;
  position: relative;
  transition: 1200ms cubic-bezier(0.22, 1, 0.36, 1);
}

.tube-gel {
  transition: 1200ms cubic-bezier(0.22, 1, 0.36, 1);
  width: 100%;
  height: 100%;
  background: #1bc891;
  left: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
  border-radius: 25px;
}

.warranty-adder {
  background: #c5c5de;
  padding: 2em;
  border-radius: 8px;
}

.warranty-products {
  .item {
    display: flex;
    background-color: #192630;
    border-radius: 8px;
    padding: 1em;
    box-sizing: border-box;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background .fa-check-circle {
      padding-right: 10px;
    }
    .fa-check-circle {
      color: #1bc891;
      margin-right: 10px;
    }
    .item-meta {
      flex: 9;
      color: white;
      .item-serial {
        padding-top: 5px;
        font-size: 0.75em;
        opacity: 0.5;
      }
    }
    .fa-trash {
      color: $red;
      text-align: center;
      flex: 1;
    }
  }
}

#dark-bg-button.Mui-disabled {
  background-color: #2b2b3e;
}

//PD

.phase-header {
  display: flex;
  justify-content: space-between;
  .phase {
    width: 100%;
    margin: 0 1em;
    text-align: center;
  }
  .phase-item {
    display: flex;
    justify-content: space-between;
    background: $gray;
    padding: 2em;
    font-weight: bold;
    font-size: 16px;
    margin-top: 1em;
    border-radius: 8px;
  }
}

.circle-button {
  background-color: black;
  color: white;
  border-radius: 8px;
  text-align: center;
  padding: 0.5em 2em;
  h2 {
    font-size: 18px;
  }
}

.pd-product-header {
  .pd-info-meta {
    padding-top: 2em;
    width: 85%;
    margin: 0 auto;
    h2,
    h3 {
      color: #9288c0;
    }
    h3 {
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    h2 {
      font-size: 1.2em;
    }
    h3,
    h1 {
      margin: 0;
      padding: 0;
    }
    .outlined {
      color: white;
      font-size: 4em;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .flex-container {
    justify-content: space-between;
    width: 600px;
    margin: 0 auto;
    .phase {
      color: white;
      padding: 0.5em 2em;
      border-radius: 25px;
    }
    .active {
      background: $blue;
    }
  }
}
.pd-checklist {
  li {
    display: flex;
    align-items: center;
  }

  .pd-text-field {
    width: 100%;
  }
}
.pd-color {
  background: $pd;
  color: white;
}

.pd-block-title {
  padding: 2em;
  font-weight: bold;
  font-size: 1.25em;
}

.note .pd-content {
  padding-top: 0;
}

.pd-content {
  padding: 2em;
}

.pd-block {
  background-color: white;
  box-shadow: $subtle-shadow;
  width: 49%;
}

.block-grid {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.pd-block.checkpoint {
  width: 100%;
  background: transparent;
  box-shadow: none;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}

.pd-block.note {
  background: $gray;
}

.stage {
  cursor: pointer;
  padding: 0.5em 1em;
  color: #aeacc4;
  &:hover {
    color: white;
  }
}

.stage.active {
  border-radius: 25px;
  color: white;
}

.price-list-card {
  h4 {
    margin: 1em 0 0.1em;
  }

  p {
    margin: 0;
    font-size: 0.7em;
  }
}

#warranty-list {
  .row-heads,
  .line-item {
    display: grid;
    grid-template-columns: 10% 40% 40% 10%;
  }
  .row-heads {
    margin-bottom: 1em;
  }
  .line-item {
    box-shadow: none !important;
    margin: 0;
  }

  .serial {
    font-weight: bold;
    cursor: pointer;
  }

  i {
    font-size: 1.5em;
    color: #93999f;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  .fa-receipt {
    &:hover {
      color: $blue;
    }
  }

  .fa-check-circle.active {
    color: $green !important;
  }

  i.far.fa-ban.declined {
    color: $red !important;
  }

  .action {
    flex: 1;

    .fa-check-circle {
      &:hover {
        color: $green;
      }
    }
    .fa-ban {
      &:hover {
        color: $red;
      }
    }
  }
}

.registration-flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.pd-phase-header {display: flex;justify-content: space-between;width: 100%;border-bottom: 1px solid #4c5156;align-items: center;}

.number-bubble {background: #29d078;width: 20px;height: 20px;align-items: center;text-align: center;border-radius: 50%;margin-right: 1em;color: #161c23;font-weight: bold;display: flex;justify-content: center;}

.pdcenter {
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  h3 {
    font-size: 1.2em;
    font-weight: bold;
    color: #161c23;
  }
}

.phase-grid-parent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pd-sidebar {
  width: 30%;
  max-width: 320px;
}
.last-updated {color: #4c5e70;padding-bottom: 1em;display: flx;}

.pd-board {
  padding: 3em;
  flex: 1;


}
.pd-boards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.pd-sales-numbers {
  color: white;
  min-height: 30vh;
}

.pd-sales-numbers, .pd-dbotm, .discontinued-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 2em ;
  background: #02060b;
  color: white;
}

.discontinued-list

.pd-dbotm {height: 25vh; border-top: 1px solid #161c23 }

.discontinued-notes {font-size: 0.75em;width: 100%;}

.discontinued-notes h3 {color: rgb(240, 117, 109);width: 100%;}

.discontinued-notes p {color: #56606a;}

.pd-progress-filled {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #4c5e70;
  border-radius: 25px;
  transition: .5s ease-in-out;
}

.pd-progress-bar {
  height: 17px;
  background: #0d1116;
  margin-top: 20px;
  border-radius: 25px;
  position: relative;
  transition: .5s ease-in-out;

}

.pd-timer {height: 30vh; border-top: 1px solid #161c23}

.pd-product img {
  max-height: 210px;
  margin: 0 auto;
  display: flex;
  max-width: 100%;;
}

.number-large {
  color: #29d078;
  font-weight: bold;
  font-size: 1.8em;
  margin: 0;
}

.pd-product ul {
  margin: 0;
  padding: 2px 20px;
  background: #abb7c8;
  border-radius: 8px;
}

.pd-product li {list-style: none;padding-bottom: 1em;}

.pd-product li p {
  font-weight: bold;
  background: #161c23;
  padding: 5px 10px;
  border-radius: 25px;
  display: table;
  color: #26c278;
}


.pd-stage {
  box-shadow: 1px 1px 20px 5px rgb(6 7 9 / 57%);
  z-index: 1;
  position: relative;
  border: 1px solid #4c5156;
  border-radius: 8px;
}


.pd-phase-header h2 {color: white;padding: 1em;margin: 0;font-size: 1.7em;}


.dbotm-image {
  background: white;
  border-radius: 10px;
}

.pd-product {
  box-shadow: $subtle-shadow;
  padding: 1em;
  margin: 1em;
  background: #dfe5ee;
  border-radius: 10px;
  color: #02060b;
  border-top: 5px solid #27bc6f;
  // display: flex;
}

.pd-header{
  width: 100%;
  background: black;
  .pd-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin: 0 auto;
    padding: 1em 0;
}
  img{
    width: 35px;
  }
  i {
    color: #23bf60;
  }
}

.hs-company-results {
  padding: 1.5em 0;
  overflow-y: scroll;
  height: 150px;
  width: 100%;
  text-align: left;
  background: white;
  border: 1px solid $gray;
  margin-bottom: 3em;
  .hs-company-item {
    padding: 1em 1.5em;
    cursor: pointer;
    &:hover {
      background: #bac1d0;
    }
  }
}
.assc-co {
  margin-top: 16px;
  width: 65%;
}

.phase-grid-parent {flex-wrap: wrap;}

.pd-sidebar {width: 100%;max-width: 100%;}

.pd-board {width: 100%;padding: 1em;}

.pd-boards {grid-template-columns: repeat(1, 1fr);}

.pd-sales-numbers {min-height: inherit;}

.pd-dbotm {height: inherit;}

.pd-timer {height: inherit;}

.pd-sidebar {display: flex;}

.sales-numbers {width: 100%;}

.priviledge {width: 100%;}
