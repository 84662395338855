// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import "scss/partials/variables";
@import "scss/breakpoints/base";
@import "scss/breakpoints/normalize.scss";

@import "scss/components/QuoteBuilder/QuoteBuilder.scss";
@import "scss/components/ProfileEditor/ProfileEditor.scss";
@import "scss/components/Admin/CompanySearch.scss";
@import "scss/components/MyCompany/ContactCard.scss";
@import "scss/components/Products/InventorySearch.scss";
@import "scss/components/Home/OverviewCard.scss";

@media only screen and (min-width: 768px) {
  @import "scss/breakpoints/768";
}

@media only screen and (min-width: 1024px) {
  @import "scss/breakpoints/1024";
}

@media only screen and (min-width: 1240px) {
  @import "scss/breakpoints/1240";
}

@media only screen and (min-width: 1340px) {
  @import "scss/breakpoints/1340";
}
