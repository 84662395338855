@import "../../../partials/variables.scss";

.back-crumb {
    border: 1px solid $gray;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    display: table;
    margin-bottom: 10px;
    i {
        color: $blue;
    }
}  

.top-floating-nav {
    position: absolute;
    top: 20px;
    display: flex;
    left: 20px;
    z-index: 999;
    div {
      &:first-child {
        margin-right: 5px;
      }
    }
  }

.icon-bubble {
    margin: auto;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: white;
      font-size: 22px;
    }
  }
  
  .icon-bubble.blue {
    background: $blue;
  }
  .icon-bubble.green {
    background: $green;
  }
  .icon-bubble.red {
    background: $red;
  }

.wizard {
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    padding-top: 130px;
    padding-bottom: 20px;
    margin: auto;
    text-align: center;
    .upload-container {
      width: 100%;
  
      .flex-container {
        justify-content: center;
      }

      .MuiDropzonePreviewList-imageContainer {
        margin: auto;
      }
    }

    .flex-item.inactive {
      filter: grayscale(1);
      opacity: 0.4;
      pointer-events: none;
    }

    ul {
      margin: 0;
      padding: 0;
      margin-bottom: 30px;
    }

    li {
      text-align: left;
      list-style: none;
      padding: 15px 0;
      border-bottom: 1px solid $gray;
    }

    h1,
    h2 {
      margin: 5px;
      padding: 0;
    }

    p {
      padding-bottom: 30px;
    }

    .logo-top {
      width: 50px;
      border-radius: 50%;
      padding: 10px;
      background: $black;
    }

    iframe {
      padding-top: 150px;
      width: 100vw !important;
    }

    .container {
      display: flex;
      max-width: 900px;
      margin: 0 auto;
      width: 90%;
      flex-wrap: wrap;
      justify-content: center;
    }

    .top-intro {
      padding-bottom: 60px;
    }

    .flex-item {
      background-color: white;
      padding: 30px;
      width: 100%;
      margin: 20px;
      position: relative;
      box-shadow: $subtle-shadow;
    }
  
    h1,
    p {
      text-align: center;
    }
  }
  
  .wizard-navigation-button-container {
    width: 100%;
    
    .wizard-navigation-card {
        padding: 12px;
    }
  }