@import "../../partials/variables.scss";

.orderItemNotes {
    background-color: whitesmoke;

    border-bottom: 1px solid $blue;
    text-align: left;
    padding: 8px;
    border-radius: 5px;
    margin: 8px auto!important;
}

.bordered-container {
    border: 1px solid rgb(202, 202, 202);
    border-radius: 8px;
    padding: 15px;
    margin: 5px;
    width: 100%;
    background-color: white;
    height: 100%;
}