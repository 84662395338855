.product-filters-container {
    padding: 24px 0;
    margin: 12px 0;
    border-bottom: 3px solid grey;
}

.prod-media-browser {
    height: 98vh;
    // width: 98vw;
    overflow: scroll;
    background: #c7c7c7;
    padding: 20px;
    .MuiInputBase-input.Mui-disabled {
      color: black !important;
      opacity: 1 !important;
    }
    .media-actions {
      font-size: 0.2em;
      text-align: right;
      position: absolute;
      width: 100%;
      opacity: 0;
      top: 10px;
      right: 10px;
      i {
        color: $gray !important;
        cursor: pointer;
        &:last-child {
          margin-left: 15px;
        }
        &:hover {
          color: $blue !important;
        }
      }
    }
    .app-icon {
      max-height: 100px;
    }
    .gallery {
      .gallery-toolbar {
        display: flex;
        align-items: center;
      }
      .gallery-search {
        flex-grow: 1;
        max-width: 400px;
      }
      .gallery-filter {
        .btn {
          margin-right: 20px;
        }
      }
      .container {
        padding-top: 50px;
        display: grid;
        text-align: center;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 15px;
        .content {
          background: white;
          padding: 20px;
          box-shadow: $subtle;
          display: grid;
          align-items: center;
          position: relative;
          &:hover {
            .media-actions {
              opacity: 1;
              transition: 0.2s;
            }
          }
        }
        i {
          font-size: 5em;
          color: $blue;
        }
        .caption {
          text-decoration: underline;
          color: $lgray;
          padding: 10px;
        }
        img {
          width: 100%;
        }
      }
    }
  }

  .single-product-line {
    padding-left: 8px;
  }

  .base-product-line {
    padding: 0 4px;
  }


.active-filter-button {
  background-color: $blue!important;
  color: white!important;
}
.inactive-filter-button {
  background-color: transparent;
  color: $blue;
}