.pd-sidebar {
    max-width: 310px;
}

.sales-numbers {
    font-size: 15px;
}
.pd-board {
    padding: 3em;

}