@import "../../partials/variables.scss";

.upload-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .upload-container,
  .metadata-container {
    width: 48%;
    background: white;
    padding: 30px 50px;
    box-shadow: $subtle-shadow;
    .success {
      i {
        color: $green;
      }
      p {
        max-width: 500px;
        margin: 0 auto;
        padding: 20px 0;
        font-size: 20px;
      }
    }
    .flex-container {
      width: 60%;
      justify-content: center;
      margin: 0 auto;
      margin-top: 20px;
    }
    svg {
      fill: $blue;
    }
    i {
      font-size: 24px;
      color: $blue;
    }
  }

  .file-uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .MuiDropzoneArea-textContainer {
        width: inherit;
    }
  }

  .bordered-uploader {
    border: 1px solid $blue!important;
  }

  