.prod-media-browser {
  .gallery {
    .container {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}



.pd-boards {grid-template-columns: repeat(4, 1fr);}
.sales-numbers {font-size: 11px;}
.pd-sidebar {max-width: 210px;}