@import "../partials/variables.scss";

.company-options-select {
    color: white!important;
    height: 48px;
    min-width: 150px;
    margin-right: 16px;
    border-bottom: 1px solid lightgray;
    svg {
        color: white;
    }
}