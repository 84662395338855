@import "../../partials/variables.scss";

// ProfileEditor.js
.profile-editor {
  padding-top: 47px;
  background-color: #03070b;

  .profile-nav {
    min-height: 75px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000000;
    width: 100%;
    background: #09151e;
    display: flex;
    justify-content: space-between;
    .flex-container {
      display: flex;
      align-items: center;
      width: 95%;
      margin: 0 auto;
      justify-content: space-around;
      .profile-info {
        display: flex;
      }
    }
    .nav-item {
      padding: 1em 2em;
      // border-right: 1px solid #06080a;
      h5 {
        color: #73787e;
        font-weight: normal;
        margin: 0;
        padding-top: 10px;
      }
      p {
        color: white;
        font-size: 16px;
        margin: 0;
        padding: 0;
      }
      // &:last-child {
      //   border-right: none;
      // }
    }
    svg {
      fill: $blue;
    }
    label {
      color: #73787e;
    }
    input {
      color: white;
    }
  }
  .collapsed {
    width: 0% !important;
  }
  .profile-col::-webkit-scrollbar {
    display: none;
  }
  .fixed-col-head {
    height: 3em;
    top: 1.25em;
    width: 100%;
    z-index: 2;
    position: fixed;
    display: flex;
    align-items: center;
  }
  .active-col {
    height: 90vh;
  }
  .profile-col {
    height: 100vh;
    position: relative;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    border-left: 1px solid #08131b;
    border-top: 1px solid #08131b;
    h3 {
      color: #73787e;
      font-weight: normal;
      font-size: 1.5em;
      padding-left: 1.5em;
    }
  }
  .pc-1 {
    background: #010407;
    padding-top: 4.5em;
    .MuiFilledInput-underline:after {
      border-bottom: none;
    }
    label {
      color: #73787e !important;
    }
  }
  .pc-2 {
    background: #03070b;
    padding-top: 5em;
  }
  .pc-3 {
    background: #03070b;
    padding-top: 5em;
  }

  .profile-option {
    cursor: pointer;
    font-size: 16px;
    border-bottom: 1px solid #08131b;
    width: 100%;
    padding: 0.5em 0;
    .profile-button {
      color: white;
      min-width: 300px;
      display: table;
      margin-left: 1em;
      border: 1px solid rgba(255, 255, 255, 0.322);
      padding: 10px 20px;
      border-radius: 50px;
      i {
        color: $blue;
      }
    }
    .profile-checkboxes {
      padding-left: 1em;
      color: white;
      svg {
        fill: $blue;
      }
    }
    .arrow {
      transform: rotate(0deg);
      transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    }

    .active {
      transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      transform: rotate(90deg);
    }
  }
}

.profile-adder {
  background: $subtle;
  padding: 2em;
  width: 650px;
  overflow-y: scroll;
  h2 {
    text-align: center;
  }
}

.fixture-search {
  .search-results {
    margin-bottom: 30px;
    .line-item {
      padding: 20px;
      font-size: 16px;
      .fa-trash-alt {
        color: $red;
      }
      .fa-edit {
        color: $neutral;
      }
      i {
        font-size: 18px;
        padding-left: 2em;
      }
    }
  }
}

.trip-text {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3em;
  letter-spacing: 14px;
  text-shadow: -5px -4px 0px #009cff99, 2px 4px #ff0045c7;
}

#available-channels {
  color: white !important;
}

.bare-text-input {
  border: none;
  background: transparent;
  outline: none !important;
}

// Channel.js, Segment.js
.profile-channel.active {
  background: #09151e;
}
.profile-channel {
  padding-left: 2em;
  align-items: center;
  border-bottom: 1px solid #08131b;
  color: white;

  .profile-order {
    text-align: center;
    color: #73787e;
    font-size: 1.5em;
    padding-right: 1em;
  }
  .channel-name {
    display: flex;
    align-items: center;
    font-size: 1.5em;
  }
}

#channel-name-edit,
#new-profile-name {
  color: white !important;
  border: none;
}
#segment-name-edit {
  background: none;
  border: none;
  font-size: 16px;
  text-align: center;
  &:focus-visible {
    outline: none !important;
  }
}
