.greeting {
  width: 100%;
}

.overview-card {
  height: 100%;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
  }

  i {
    position: absolute;
    top: 20px;
    right: 20px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
  }

  .card {
    color: white;
    min-height: 400px;
    height: 100%;
    padding: 30px;
    overflow: hidden;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    transition: 0.75s;

    .top-info {
      position: relative;
      width: 100%;
    }

    .no-hover {
      &:hover {
        transition: none;
        transform: none;
      }
    }

  }
}