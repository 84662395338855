.grid-search {

    .grid-search-headers {
      padding: 0 15px;
      margin: 5px;
      font-weight: bold;
      color: #5b5f69;
    }
  
    .grid-search-result {
      width: 100%;
      padding: 15px;
      margin: 3px 0;
    }
  
    .search-filter-option {
      font-size: 0.9em;
    }
  
    .body-message {
      margin: 25px auto;
      font-size: 2em;
      font-weight: bold;
    }
  
    .container-spacing {
      margin: 15px 0;
    }
  
    .result-name {
      text-align: left;
    }
  
    .no-underline {
      text-decoration: none;
      border-bottom: none;
    }
  
    // Utility - Layout
    .center-text {
      text-align: center;
    }
  
    .center {
      margin: 0 auto;
    }
  
    // Utility - Color
    .blue {
      color: blue;
    }
  
    .tomato {
      color: tomato;
    }
  
    .orange {
      color: orange;
    }
  
    .green {
      color: green;
    }
  
    .status.blue {
      color: white;
    }
  }