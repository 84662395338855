// #user-permissions {
//     width: 100%;
//     .row-heads {
//         display: grid;
//         grid-template-columns: 50% 50%;
//         margin-bottom: 1em;
//     }

//     .line-item {
//         display: grid;
//         grid-template-columns: 50% 50%;
//         box-shadow: none !important;
//         margin: 0;
//     }
// }


#hubspot-property-requirements {
    .row-heads {
      display: grid;
      grid-template-columns: 35% 35% 15% 15%;
      margin-bottom: 1em;
    }
  
    .line-item {
      display: grid;
      grid-template-columns: 35% 35% 15% 15%;
      box-shadow: none !important;
      margin: 0;
    }
  }